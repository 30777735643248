import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Select, MenuItem, Button } from '@mui/material';

const EditTransactionDialog = ({ open, transaction, handleClose, handleEditChange, handleEditSave }) => (
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ bgcolor: 'primary.main', color: 'background.paper' }}>Edit Transaction</DialogTitle>
        <DialogContent>
            <TextField
                fullWidth
                label="Description"
                value={transaction?.description || ''}
                onChange={(e) => handleEditChange('description', e.target.value)}
                margin="normal"
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Amount"
                type="number"
                value={transaction?.amount || ''}
                onChange={(e) => handleEditChange('amount', e.target.value)}
                margin="normal"
                variant="outlined"
            />
            <Select
                fullWidth
                value={transaction?.type || 'income'}
                onChange={(e) => handleEditChange('type', e.target.value)}
                margin="normal"
                variant="outlined"
            >
                <MenuItem value="income">Income</MenuItem>
                <MenuItem value="expense">Expense</MenuItem>
            </Select>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Cancel
            </Button>
            <Button onClick={handleEditSave} variant="contained" color="primary">
                Save
            </Button>
        </DialogActions>
    </Dialog>
);

export default EditTransactionDialog