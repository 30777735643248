import React, { useState } from 'react';
import axios from 'axios';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import EditUserDialog from './EditUserDialog';
import DeleteConfirmationDialog from './DeleteConfirmationDialog';

const SubscribersView = ({ users, fetchUsers, showSnackbar }) => {
    const [editUser, setEditUser] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, userId: null });
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const handleEditUser = (user) => {
        setEditUser(user);
        setOpenEditDialog(true);
    };

    const handleDeleteClick = (userId) => {
        setDeleteConfirmation({ open: true, userId });
    };

    const handleDeleteConfirm = async () => {
        if (deleteConfirmation.userId) {
            try {
                await axios.delete(`${baseURL}/api/admin/users/${deleteConfirmation.userId}`, {
                    headers: { Authorization: localStorage.getItem('token') }
                });
                fetchUsers();
                showSnackbar('User deleted successfully');
            } catch (error) {
                console.error('Error deleting user:', error);
                showSnackbar('Error deleting user');
            }
        }
        setDeleteConfirmation({ open: false, userId: null });
    };

    const handleSaveUser = async (updatedUser) => {
        try {
            await axios.put(`${baseURL}/api/admin/users/${updatedUser.id}`,
                {
                    username: updatedUser.username,
                    is_pro: updatedUser.is_pro
                },
                {
                    headers: { Authorization: localStorage.getItem('token') }
                }
            );
            fetchUsers();
            setOpenEditDialog(false);
            showSnackbar('User updated successfully');
        } catch (error) {
            console.error('Error updating user:', error);
            showSnackbar('Error updating user');
        }
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Username</TableCell>
                            <TableCell>Is Pro</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.id}</TableCell>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>{user.is_pro ? 'Yes' : 'No'}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEditUser(user)} color="primary">
                                        <Edit />
                                    </IconButton>
                                    <IconButton onClick={() => handleDeleteClick(user.id)} color="error">
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <EditUserDialog
                open={openEditDialog}
                user={editUser}
                onClose={() => setOpenEditDialog(false)}
                onSave={handleSaveUser}
            />

            <DeleteConfirmationDialog
                open={deleteConfirmation.open}
                onClose={() => setDeleteConfirmation({ open: false, userId: null })}
                onConfirm={handleDeleteConfirm}
            />
        </>
    );
};

export default SubscribersView;