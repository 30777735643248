import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Box, Snackbar, ThemeProvider, Typography, Button } from '@mui/material';
import axios from 'axios';
import { DragDropContext } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import CashflowAppBar from './CashflowAppBar';
import AddTransactionForm from './AddTransactionForm';
import TransactionList from './TransactionList';
import CashflowChart from './CashflowChart';
import EditTransactionDialog from './EditTransactionDialog';
import NotificationToast from '../NotificationToast';
import theme from '../theme';

const Main = ({ user, onLogout, isPro }) => {
    const [transactions, setTransactions] = useState([]);
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');
    const [type, setType] = useState('income');
    const [editDialog, setEditDialog] = useState({ open: false, transaction: null });
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });
    const [leftWidth, setLeftWidth] = useState(50);
    const [isLoading, setIsLoading] = useState(true);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationAction, setNotificationAction] = useState('');
    const [canAccessChart, setCanAccessChart] = useState(false);
    const containerRef = useRef(null);
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const parseAmount = (value) => parseFloat(value) || 0;
    const formatAmount = (value) => parseAmount(value).toFixed(2);
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? 'Invalid Date' : date.toISOString().split('T')[0];
    };

    const showSnackbar = useCallback((message) => {
        setSnackbar({ open: true, message });
    }, []);

    const calculateRunningBalance = useCallback((transactionList) => {
        let balance = 0;
        return transactionList.map(transaction => {
            const amount = parseAmount(transaction.amount);
            balance += transaction.type === 'income' ? amount : -amount;
            return { ...transaction, amount, balance: Number(balance.toFixed(2)), date: formatDate(transaction.date) };
        });
    }, []);

    const fetchTransactions = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(baseURL + '/api/transactions', {
                headers: { Authorization: localStorage.getItem('token') }
            });
            const formattedTransactions = response.data.map(transaction => ({
                ...transaction,
                date: formatDate(transaction.date)
            }));
            setTransactions(calculateRunningBalance(formattedTransactions));
        } catch (error) {
            console.error('Error fetching transactions:', error);
            showSnackbar('Error fetching transactions');
        } finally {
            setIsLoading(false);
        }
    }, [calculateRunningBalance, baseURL, showSnackbar]);

    useEffect(() => {
        fetchTransactions();

        // Update this line to use the isPro prop directly
        setCanAccessChart(isPro);

        const isExpired = localStorage.getItem('isExpired') === 'true';
        const isExpiringSoon = localStorage.getItem('isExpiringSoon') === 'true';

        if (isExpired) {
            setNotificationMessage('Your Pro plan has expired. Renew now to continue enjoying Pro features!');
            setNotificationAction('Renew Now');
            setNotificationOpen(true);
        } else if (isExpiringSoon) {
            setNotificationMessage('Your Pro plan is expiring soon. Renew now to avoid interruption!');
            setNotificationAction('Renew Now');
            setNotificationOpen(true);
        }
    }, [fetchTransactions, isPro]);

    const addTransaction = async () => {
        if (description && amount) {
            if (!isPro && transactions.length >= 10) {
                showSnackbar("Max transactions reached for free plan");
                return;
            }
            try {
                const response = await axios.post(baseURL + '/api/transactions',
                    { description, amount: parseAmount(amount), type },
                    { headers: { Authorization: localStorage.getItem('token') } }
                );
                const newTransaction = { ...response.data, date: formatDate(response.data.date) };
                setTransactions(prevTransactions => calculateRunningBalance([...prevTransactions, newTransaction]));
                setDescription('');
                setAmount('');
                showSnackbar('Transaction added');
            } catch (error) {
                console.error('Error adding transaction:', error);
                showSnackbar('Error adding transaction');
            }
        }
    };

    const handleEdit = (transaction) => {
        setEditDialog({ open: true, transaction: { ...transaction } });
    };

    const handleEditSave = async () => {
        try {
            const updatedTransaction = {
                ...editDialog.transaction,
                amount: parseAmount(editDialog.transaction.amount)
            };
            await axios.put(baseURL + `/api/transactions/${updatedTransaction.id}`,
                updatedTransaction,
                { headers: { Authorization: localStorage.getItem('token') } }
            );
            setTransactions(prevTransactions => {
                const updatedTransactions = prevTransactions.map(t =>
                    t.id === updatedTransaction.id ? updatedTransaction : t
                );
                return calculateRunningBalance(updatedTransactions);
            });
            setEditDialog({ open: false, transaction: null });
            showSnackbar('Transaction updated');
        } catch (error) {
            console.error('Error updating transaction:', error);
            showSnackbar('Error updating transaction');
        }
    };

    const handleEditChange = (field, value) => {
        setEditDialog(prev => ({
            ...prev,
            transaction: {
                ...prev.transaction,
                [field]: field === 'amount' ? parseFloat(value) : value
            }
        }));
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(baseURL + `/api/transactions/${id}`,
                { headers: { Authorization: localStorage.getItem('token') } }
            );
            setTransactions(prevTransactions => {
                const updatedTransactions = prevTransactions.filter(t => t.id !== id);
                return calculateRunningBalance(updatedTransactions);
            });
            showSnackbar('Transaction deleted');
        } catch (error) {
            console.error('Error deleting transaction:', error);
            showSnackbar('Error deleting transaction');
        }
    };

    const handleDragEnd = useCallback(async (result) => {
        if (!result.destination) return;

        const items = Array.from(transactions);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        const updatedTransactions = calculateRunningBalance(items);
        setTransactions(updatedTransactions);

        try {
            await axios.post(baseURL + '/api/transactions/reorder',
                { transactions: updatedTransactions.map(item => item.id) },
                { headers: { Authorization: localStorage.getItem('token') } }
            );
            showSnackbar('Transaction order updated');
        } catch (error) {
            console.error('Error updating transaction order:', error);
            showSnackbar('Error updating transaction order');
            fetchTransactions();
        }
    }, [transactions, calculateRunningBalance, showSnackbar, fetchTransactions, baseURL]);

    const calculateChartData = (transactionList) => {
        let balance = 0;
        return transactionList.map(transaction => {
            const amount = parseAmount(transaction.amount);
            balance += transaction.type === 'income' ? amount : -amount;
            return {
                date: formatDate(transaction.date),
                balance: Number(balance.toFixed(2))
            };
        });
    };

    const chartData = calculateChartData(transactions);

    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };

    const handleUpgradeClick = () => {
        navigate('/paypal-payment');
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', color: 'text.primary', display: 'flex', flexDirection: 'column' }}>
                <CashflowAppBar isPro={isPro} onLogout={onLogout} />
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Container maxWidth="xl" sx={{ flexGrow: 1, mt: 4, mb: 4 }}>
                        <Box display="flex" style={{ position: 'relative' }} ref={containerRef}>
                            <Box sx={{ width: `${leftWidth}%`, pr: 2 }}>
                                <AddTransactionForm
                                    description={description}
                                    amount={amount}
                                    type={type}
                                    setDescription={setDescription}
                                    setAmount={setAmount}
                                    setType={setType}
                                    addTransaction={addTransaction}
                                />
                                <TransactionList
                                    transactions={transactions}
                                    isPro={isPro}
                                    isLoading={isLoading}
                                    handleEdit={handleEdit}
                                    handleDelete={handleDelete}
                                    formatAmount={formatAmount}
                                    formatDate={formatDate}
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: '5px',
                                    bgcolor: '#ccc',
                                    cursor: 'col-resize',
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    left: `${leftWidth}%`,
                                    transform: 'translateX(-50%)',
                                    zIndex: 1000,
                                    '&:hover': { bgcolor: '#999' }
                                }}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    const handleMouseMove = (e) => {
                                        if (containerRef.current) {
                                            const containerRect = containerRef.current.getBoundingClientRect();
                                            const newLeftWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100;
                                            setLeftWidth(Math.max(30, Math.min(70, newLeftWidth)));
                                        }
                                    };
                                    const handleMouseUp = () => {
                                        document.removeEventListener('mousemove', handleMouseMove);
                                        document.removeEventListener('mouseup', handleMouseUp);
                                    };
                                    document.addEventListener('mousemove', handleMouseMove);
                                    document.addEventListener('mouseup', handleMouseUp);
                                }}
                            />
                            <Box sx={{ width: `${100 - leftWidth}%`, pl: 2 }}>
                                {canAccessChart ? (
                                    <CashflowChart chartData={chartData} />
                                ) : (
                                    <Box sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        bgcolor: 'background.paper',
                                        borderRadius: 2,
                                        p: 3,
                                        textAlign: 'center'
                                    }}>
                                        <Typography variant="h5" gutterBottom>
                                            Upgrade to Pro to Access Advanced Charts
                                        </Typography>
                                        <Typography variant="body1" paragraph>
                                            Get insights into your financial trends with our advanced charting feature.
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleUpgradeClick}
                                        >
                                            Upgrade to Pro
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Container>
                </DragDropContext>
                <EditTransactionDialog
                    open={editDialog.open}
                    transaction={editDialog.transaction}
                    handleClose={() => setEditDialog({ open: false, transaction: null })}
                    handleEditChange={handleEditChange}
                    handleEditSave={handleEditSave}
                />
                <NotificationToast
                    open={notificationOpen}
                    message={notificationMessage}
                    onClose={handleNotificationClose}
                    action={notificationAction}
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={snackbar.open}
                    autoHideDuration={3000}
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    message={snackbar.message}
                />
            </Box>
        </ThemeProvider>
    );
};

export default Main;