import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Select,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
    Box,
} from '@mui/material';

const EditUserDialog = ({ open, user, onClose, onSave }) => {
    const [editedUser, setEditedUser] = useState(null);

    useEffect(() => {
        if (user) {
            setEditedUser({ ...user });
        }
    }, [user]);

    const handleChange = (field, value) => {
        setEditedUser(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSave = () => {
        onSave(editedUser);
    };

    if (!editedUser) return null;

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Edit User</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                    <TextField
                        autoFocus
                        label="Username"
                        fullWidth
                        value={editedUser.username || ''}
                        onChange={(e) => handleChange('username', e.target.value)}
                    />
                    <TextField
                        label="Email"
                        fullWidth
                        value={editedUser.email || ''}
                        onChange={(e) => handleChange('email', e.target.value)}
                    />
                    <FormControl fullWidth>
                        <InputLabel>Subscription Status</InputLabel>
                        <Select
                            value={editedUser.is_pro ? 'true' : 'false'}
                            onChange={(e) => handleChange('is_pro', e.target.value === 'true')}
                            label="Subscription Status"
                        >
                            <MenuItem value="true">Pro</MenuItem>
                            <MenuItem value="false">Free</MenuItem>
                        </Select>
                    </FormControl>
                    {editedUser.is_pro && (
                        <TextField
                            label="Subscription Expiry"
                            type="date"
                            fullWidth
                            value={editedUser.plan_expiration ? editedUser.plan_expiration.split('T')[0] : ''}
                            onChange={(e) => handleChange('plan_expiration', e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} variant="contained" color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditUserDialog;