import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';

const DashboardView = ({ stats }) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Total Subscribers</Typography>
                        <Typography variant="h3">{stats.totalSubscribers}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Total Subscriptions</Typography>
                        <Typography variant="h3">{stats.totalSubscriptions}</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Email Delivery Rate</Typography>
                        <Typography variant="h3">{stats.emailDeliveryRate}%</Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default DashboardView;