import React from 'react';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Box,
} from '@mui/material';
import {
    Dashboard as DashboardIcon,
    People as PeopleIcon,
    Person as PersonIcon,
} from '@mui/icons-material';

const AdminSidebar = ({ activeTab, setActiveTab }) => {
    const menuItems = [
        { text: 'Dashboard', icon: <DashboardIcon />, value: 'dashboard' },
        { text: 'Subscribers', icon: <PeopleIcon />, value: 'subscribers' },
        { text: 'Profile', icon: <PersonIcon />, value: 'profile' },
    ];

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: 240,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                <List>
                    {menuItems.map((item) => (
                        <ListItem
                            button
                            key={item.text}
                            onClick={() => setActiveTab(item.value)}
                            selected={activeTab === item.value}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    );
};

export default AdminSidebar;