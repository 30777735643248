import React from 'react';
import { Paper, Typography, TextField, Select, MenuItem, Button, Grid, InputAdornment } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: theme.spacing(2),
    backgroundColor: '#f8f9fa',
    boxShadow: 'none',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5, 3),
    fontWeight: 600,
}));

const AddTransactionForm = ({ description, amount, type, setDescription, setAmount, setType, addTransaction }) => (
    <StyledPaper>
        <Typography variant="h6" gutterBottom sx={{ color: 'text.primary', fontWeight: 500, mb: 3 }}>
            Add Transaction
        </Typography>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={5}>
                <TextField
                    fullWidth
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    variant="outlined"
                    sx={{ backgroundColor: 'white' }}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    fullWidth
                    label="Amount"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">JOD</InputAdornment>,
                    }}
                    sx={{ backgroundColor: 'white' }}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Select
                    fullWidth
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    variant="outlined"
                    sx={{ backgroundColor: 'white' }}
                >
                    <MenuItem value="income">Income</MenuItem>
                    <MenuItem value="expense">Expense</MenuItem>
                </Select>
            </Grid>
        </Grid>
        <StyledButton
            variant="contained"
            color="primary"
            onClick={addTransaction}
            fullWidth
        >
            Add Transaction
        </StyledButton>
    </StyledPaper>
);

export default AddTransactionForm;