import React, { useEffect } from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const PaymentSuccessPage = ({ updateUserStatus }) => {
    useEffect(() => {
        // Assuming the payment was successful
        updateUserStatus(true);
    }, [updateUserStatus]);

    return (
        <Container maxWidth="md" sx={{ mt: 12, mb: 12, textAlign: 'center' }}>
            <Typography variant="h2" gutterBottom>
                Payment Successful!
            </Typography>
            <Typography variant="h5" color="text.secondary" paragraph>
                Thank you for your purchase. Your Pro plan is now active.
            </Typography>
            <Button
                component={Link}
                to="/app"
                variant="contained"
                color="primary"
                size="large"
                sx={{ mt: 4 }}
            >
                Return to App
            </Button>
        </Container>
    );
};

export default PaymentSuccessPage;