import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Snackbar,
    IconButton,
} from '@mui/material';
import { ExitToApp, Close } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme';
import AdminSidebar from './AdminSidebar';
import DashboardView from './DashboardView';
import SubscribersView from './SubscribersView';
import ProfileView from './ProfileView';

// Import logo
import logo from '../../assets/cashfloweelogodefault.png';

const AdminDashboard = ({ onLogout }) => {
    const [activeTab, setActiveTab] = useState('dashboard');
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });
    const [dashboardStats, setDashboardStats] = useState({
        totalSubscribers: 0,
        totalSubscriptions: 0,
        emailDeliveryRate: 0,
    });
    const [users, setUsers] = useState([]);
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (activeTab === 'dashboard') {
            fetchDashboardStats();
        } else if (activeTab === 'subscribers') {
            fetchUsers();
        }
    }, [activeTab]);

    const fetchDashboardStats = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/admin/dashboard-stats`, {
                headers: { Authorization: localStorage.getItem('token') }
            });
            setDashboardStats(response.data);
        } catch (error) {
            console.error('Error fetching dashboard stats:', error);
            showSnackbar('Error fetching dashboard stats');
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/admin/users`, {
                headers: { Authorization: localStorage.getItem('token') }
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            showSnackbar('Error fetching users');
        }
    };

    const showSnackbar = (message) => {
        setSnackbar({ open: true, message });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                            <img src={logo} alt="Cashflowee Logo" style={{ marginRight: 10, height: 40 }} />
                            <Typography variant="h6" style={{ flexGrow: 1, color: '#FFFFFF', fontWeight: 700 }}>
                                Cashflowee Admin
                            </Typography>
                        </Box>
                        <Button color="inherit" onClick={onLogout} startIcon={<ExitToApp />}
                            sx={{ color: '#FFFFFF', '&:hover': { backgroundColor: 'rgba(255,255,255,0.1)' } }}>
                            Logout
                        </Button>
                    </Toolbar>
                </AppBar>
                <AdminSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Toolbar />
                    {activeTab === 'dashboard' && <DashboardView stats={dashboardStats} />}
                    {activeTab === 'subscribers' && <SubscribersView users={users} fetchUsers={fetchUsers} showSnackbar={showSnackbar} />}
                    {activeTab === 'profile' && <ProfileView />}
                </Box>
            </Box>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={snackbar.message}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleCloseSnackbar}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                }
            />
        </ThemeProvider>
    );
};

export default AdminDashboard;