import React from 'react';
import { Paper, Typography, Box, useTheme } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, ReferenceLine } from 'recharts';

const CashflowChart = ({ chartData }) => {
    const theme = useTheme();

    const formatXAxis = (tickItem) => {
        const date = new Date(tickItem);
        return date.toLocaleString('default', { month: 'short' });
    };

    const formatYAxis = (value) => `JOD ${value}`;

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const value = payload[0].value;
            return (
                <Box
                    sx={{
                        backgroundColor: 'background.paper',
                        border: '1px solid #ccc',
                        p: 2,
                        borderRadius: 1,
                    }}
                >
                    <Typography variant="body2">{label}</Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: value >= 0 ? 'primary.main' : 'error.main' }}
                    >
                        Balance: JOD {value.toFixed(2)}
                    </Typography>
                </Box>
            );
        }
        return null;
    };

    const CustomizedDot = (props) => {
        const { cx, cy, value } = props;
        return (
            <circle
                cx={cx}
                cy={cy}
                r={4}
                fill={value >= 0 ? theme.palette.primary.main : theme.palette.error.main}
                stroke={theme.palette.background.paper}
                strokeWidth={2}
            />
        );
    };

    return (
        <Paper
            elevation={0}
            sx={{
                p: 3,
                height: '100%',
                borderRadius: 4,
                backgroundColor: '#f8f9fa',
            }}
        >
            <Typography variant="h6" gutterBottom sx={{ color: 'text.primary', fontWeight: 500 }}>
                Cashflow Chart
            </Typography>
            <Box sx={{ width: '100%', height: 'calc(100% - 40px)' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={chartData}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                        <XAxis
                            dataKey="date"
                            tickFormatter={formatXAxis}
                            stroke="#999"
                            tick={{ fill: '#999', fontSize: 12 }}
                        />
                        <YAxis
                            tickFormatter={formatYAxis}
                            stroke="#999"
                            tick={{ fill: '#999', fontSize: 12 }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <defs>
                            <linearGradient id="colorBalance" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={theme.palette.primary.light} stopOpacity={0.8} />
                                <stop offset="95%" stopColor={theme.palette.primary.light} stopOpacity={0.1} />
                            </linearGradient>
                        </defs>
                        <ReferenceLine y={0} stroke={theme.palette.error.main} strokeDasharray="10 10" />
                        <Area
                            type="monotone"
                            dataKey="balance"
                            stroke="none"
                            fillOpacity={1}
                            fill="url(#colorBalance)"
                        />
                        <Line
                            type="monotone"
                            dataKey="balance"
                            stroke={theme.palette.primary.main}
                            strokeWidth={2}
                            dot={<CustomizedDot />}
                            activeDot={{ r: 8, fill: theme.palette.primary.main, strokeWidth: 0 }}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </Box>
        </Paper>
    );
};

export default CashflowChart;