import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Paper, Typography, Button, CircularProgress, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        fetchProfile();
    }, []);

    const fetchProfile = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/profile`, {
                headers: { Authorization: localStorage.getItem('token') }
            });
            setProfile(response.data);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching profile:', err);
            setError('Error fetching profile');
            setLoading(false);
        }
    };

    const handleRenew = () => {
        navigate('/paypal-payment');
    };

    if (loading) return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <CircularProgress />
        </Box>
    );

    if (error) return (
        <Container maxWidth="sm">
            <Typography color="error" align="center">{error}</Typography>
        </Container>
    );

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
                <Typography variant="h4" gutterBottom>User Profile</Typography>
                <Typography>Username: {profile.username}</Typography>
                <Typography>Email: {profile.email}</Typography>
                <Typography>Plan: {profile.isPro ? 'Pro' : 'Free'}</Typography>
                {profile.isPro && (
                    <>
                        <Typography>
                            Expiration Date: {new Date(profile.planExpiration).toLocaleDateString()}
                        </Typography>
                        <Typography>Subscription ID: {profile.subscriptionId}</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleRenew}
                            sx={{ mt: 2 }}
                        >
                            Renew Subscription
                        </Button>
                    </>
                )}
                {!profile.isPro && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleRenew}
                        sx={{ mt: 2 }}
                    >
                        Upgrade to Pro
                    </Button>
                )}
            </Paper>
        </Container>
    );
};

export default ProfilePage;