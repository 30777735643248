import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#72cc50',
            light: '#bfd835',
            dark: '#029975',
        },
        secondary: {
            main: '#bfd835',
            light: '#d3e85a',
            dark: '#9eb82a',
        },
        background: {
            default: '#fff',
            paper: '#FFFFFF',
            gray: '#303030'
        },
        text: {
            primary: '#333333',
            secondary: '#666666',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontWeight: 700,
            fontSize: '4rem',
        },
        h2: {
            fontWeight: 700,
            fontSize: '3rem',
        },
        h3: {
            fontWeight: 600,
            fontSize: '2.5rem',
        },
        h5: {
            fontWeight: 500,
            fontSize: '1.5rem',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    textTransform: 'none',
                    fontWeight: 400,
                    padding: '10px 20px',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                },
            },
        },
    },
});

export default theme;