import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
    Stack,
    TextInput,
    Button,
    Link as CarbonLink,
    Theme,
    Header,
    HeaderName,
    InlineNotification
} from '@carbon/react';
import { Login, Password, Email } from '@carbon/icons-react';
import axios from 'axios';
import logo from '../assets/logoicon.png';

const SignupPage = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const validatePassword = () => {
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return false;
        }
        if (password.length < 8) {
            setError('Password must be at least 8 characters long');
            return false;
        }
        return true;
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        setError('');

        if (!validatePassword()) {
            return;
        }

        try {
            await axios.post(baseURL + '/api/register', { username, email, password });
            navigate('/login');
        } catch (error) {
            console.error('Signup error:', error);
            setError('Error registering user. Please try again.');
        }
    };

    return (
        <Theme theme="white">
            <div className="signup-page">
                <Header className="signup-header">
                    <HeaderName prefix="" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                        <img src={logo} alt="Cashflowee" />
                        Cashflowee
                    </HeaderName>
                </Header>

                <div className="signup-container">
                    <div className="signup-content">
                        <Stack gap={7}>
                            <div className="signup-header-content">
                                <h1>Create your account</h1>
                                <p>Join Cashflowee and take control of your finances</p>
                            </div>

                            <form onSubmit={handleSignup}>
                                <Stack gap={5}>
                                    {error && (
                                        <InlineNotification
                                            kind="error"
                                            title="Error"
                                            subtitle={error}
                                            hideCloseButton
                                        />
                                    )}

                                    <TextInput
                                        id="username"
                                        labelText="Username"
                                        placeholder="Choose a username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        renderIcon={Login}
                                        required
                                    />

                                    <TextInput
                                        id="email"
                                        labelText="Email"
                                        placeholder="Enter your email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        renderIcon={Email}
                                        required
                                    />

                                    <TextInput.PasswordInput
                                        id="password"
                                        labelText="Password"
                                        placeholder="Create a password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        renderIcon={Password}
                                        required
                                        helperText="Password must be at least 8 characters long"
                                    />

                                    <TextInput.PasswordInput
                                        id="confirmPassword"
                                        labelText="Confirm Password"
                                        placeholder="Confirm your password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        renderIcon={Password}
                                        required
                                    />

                                    <Button
                                        type="submit"
                                        size="lg"
                                        className="signup-button"
                                    >
                                        Create Account
                                    </Button>
                                </Stack>
                            </form>

                            <div className="login-prompt">
                                <p>
                                    Already have an account?{' '}
                                    <CarbonLink as={Link} to="/login">Log in</CarbonLink>
                                </p>
                            </div>
                        </Stack>
                    </div>
                </div>
            </div>
        </Theme>
    );
};

export default SignupPage;