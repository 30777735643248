import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Header as CarbonHeader,
    HeaderNavigation,
    HeaderMenuItem,
    HeaderName,
    Grid,
    Column,
    Button,
    Tile,
    Link,
    Tag,
    Theme
} from '@carbon/react';
import {
    Dashboard,
    Currency,
    Analytics
} from '@carbon/icons-react';
import logo from '../assets/logoicon.png';
import introVideo from '../assets/cashfloweeintro.mp4';

const CarbonLandingPage = () => {
    const navigate = useNavigate();

    const features = [
        {
            title: 'Quick Transaction Entry',
            icon: <Dashboard size={32} />,
            description: 'Add income and expenses in seconds with our streamlined interface.'
        },
        {
            title: 'Instant Balance Updates',
            icon: <Currency size={32} />,
            description: 'See your financial status change in real-time as you record transactions.'
        },
        {
            title: 'Smart Analytics',
            icon: <Analytics size={32} />,
            description: 'Gain insights into your spending patterns with detailed analytics.'
        }
    ];

    const pricingPlans = [
        {
            title: 'Free',
            price: '0',
            period: 'forever',
            features: [
                'Up to 10 transactions/month',
                'Basic reporting',
                'Email support'
            ]
        },
        {
            title: 'Pro',
            price: '2.99',
            period: '/month',
            featured: true,
            features: [
                'Unlimited transactions',
                'Advanced reporting',
                'Priority support',
                'Data export',
                'Multiple currencies'
            ]
        }
    ];

    return (
        <Theme theme="white">
            <div className="landing-page">
                <CarbonHeader aria-label="Cashflowee">
                    <HeaderName prefix="">
                        <img src={logo} alt="Cashflowee" style={{ height: '20px', marginRight: '8px' }} />
                        Cashflowee
                    </HeaderName>
                    <HeaderNavigation aria-label="Cashflowee">
                        <HeaderMenuItem onClick={() => navigate('/login')}>Login</HeaderMenuItem>
                        <HeaderMenuItem onClick={() => navigate('/signup')}>Sign Up</HeaderMenuItem>
                    </HeaderNavigation>
                </CarbonHeader>

                <div className="landing-content">
                    <Grid className="landing-page__banner" fullWidth>
                        <Column lg={16} md={8} sm={4}>
                            <div className="hero-content">
                                <h1>Track Your Money in Minutes, Not Hours</h1>
                                <p>Simple financial tracking for busy professionals. No complicated spreadsheets needed.</p>
                                <Button size="lg" onClick={() => navigate('/signup')}>
                                    Try It Free
                                </Button>
                            </div>
                        </Column>
                        <Column lg={16} md={8} sm={4}>
                            <div className="video-container">
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    style={{ width: '100%', maxWidth: '1000px', margin: '0 auto', display: 'block' }}
                                >
                                    <source src={introVideo} type="video/mp4" />
                                </video>
                            </div>
                        </Column>
                    </Grid>

                    <Grid className="landing-features" fullWidth>
                        {features.map((feature, index) => (
                            <Column key={index} lg={5} md={4} sm={4}>
                                <Tile className="feature-tile">
                                    <div className="feature-icon">{feature.icon}</div>
                                    <h3>{feature.title}</h3>
                                    <p>{feature.description}</p>
                                </Tile>
                            </Column>
                        ))}
                    </Grid>

                    <Grid className="landing-pricing" fullWidth>
                        {pricingPlans.map((plan, index) => (
                            <Column key={index} lg={8} md={4} sm={4}>
                                <Tile className={`pricing-tile ${plan.featured ? 'featured' : ''}`}>
                                    <div className="pricing-header">
                                        <h3>{plan.title}</h3>
                                        {plan.featured && <Tag type="blue">Most Popular</Tag>}
                                    </div>
                                    <div className="pricing-price">
                                        <span className="currency">$</span>
                                        <span className="amount">{plan.price}</span>
                                        <span className="period">{plan.period}</span>
                                    </div>
                                    <ul className="pricing-features">
                                        {plan.features.map((feature, featureIndex) => (
                                            <li key={featureIndex}>{feature}</li>
                                        ))}
                                    </ul>
                                    <Button
                                        kind={plan.featured ? 'primary' : 'tertiary'}
                                        size="lg"
                                        className="pricing-button"
                                        onClick={() => navigate(plan.featured ? '/paypal-payment' : '/signup')}
                                    >
                                        {plan.featured ? 'Upgrade Now' : 'Get Started'}
                                    </Button>
                                </Tile>
                            </Column>
                        ))}
                    </Grid>

                    <Grid className="landing-footer" fullWidth>
                        <Column lg={16}>
                            <footer>
                                <p>© {new Date().getFullYear()} Cashflowee. All rights reserved.</p>
                                <div className="footer-links">
                                    <Link href="#">Privacy Policy</Link>
                                    <Link href="#">Terms of Service</Link>
                                </div>
                            </footer>
                        </Column>
                    </Grid>
                </div>
            </div>
        </Theme>
    );
};

export default CarbonLandingPage;