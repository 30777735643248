import React from 'react';
import { Paper, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Box, CircularProgress } from '@mui/material';
import { Edit, Delete, Add, Remove, DragIndicator } from '@mui/icons-material';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    backgroundColor: '#f8f9fa',
    boxShadow: 'none',
    transition: '0.3s',
    '&:hover': {
        boxShadow: '0 8px 24px rgba(0,0,0,0.10)',
    },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: 'white',
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    transition: 'background-color 0.2s ease',
    '&:hover': {
        boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
    },
}));

const TransactionList = ({ transactions, isPro, isLoading, handleEdit, handleDelete, formatAmount, formatDate }) => (
    <StyledPaper>
        <Typography variant="h6" gutterBottom sx={{ color: 'text.primary', fontWeight: 500, mb: 3 }}>
            Transactions
        </Typography>
        {!isPro && (
            <Typography variant="body2" color="text.secondary" gutterBottom>
                Free plan: {transactions.length}/10 transactions used
            </Typography>
        )}
        {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height={200}>
                <CircularProgress />
            </Box>
        ) : (
            <Droppable droppableId="transactionsList">
                {(provided) => (
                    <List {...provided.droppableProps} ref={provided.innerRef}>
                        {transactions.map((transaction, index) => (
                            <Draggable key={transaction.id.toString()} draggableId={transaction.id.toString()} index={index}>
                                {(provided, snapshot) => (
                                    <StyledListItem
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        sx={{
                                            backgroundColor: snapshot.isDragging ? 'rgba(0, 0, 0, 0.05)' : 'white',
                                            transform: snapshot.isDragging ? 'rotate(2deg)' : 'none',
                                            zIndex: snapshot.isDragging ? 1 : 'auto',
                                        }}
                                    >
                                        <DragIndicator sx={{ mr: 2, color: 'text.secondary' }} />
                                        <ListItemText
                                            primary={transaction.description}
                                            secondary={formatDate(transaction.date)}
                                            primaryTypographyProps={{ fontWeight: 500 }}
                                        />
                                        <ListItemSecondaryAction sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography
                                                color={transaction.type === 'income' ? 'primary.main' : 'error.main'}
                                                sx={{ mr: 2, display: 'flex', alignItems: 'center', fontWeight: 500 }}
                                            >
                                                {transaction.type === 'income' ? <Add fontSize="small" /> : <Remove fontSize="small" />}
                                                JOD{formatAmount(transaction.amount)}
                                            </Typography>
                                            <Typography variant="body2" sx={{ mr: 2, color: 'text.secondary' }}>
                                                Balance: JOD{formatAmount(transaction.balance)}
                                            </Typography>
                                            <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(transaction)} size="small">
                                                <Edit />
                                            </IconButton>
                                            <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(transaction.id)} size="small">
                                                <Delete />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </StyledListItem>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        )}
        <Typography variant="h6" align="right" sx={{ mt: 3, fontWeight: 600, color: 'primary.main' }}>
            Ending Balance: JOD{formatAmount(transactions[transactions.length - 1]?.balance || 0)}
        </Typography>
    </StyledPaper>
);

export default TransactionList;