import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
    Stack,
    Grid,
    Column,
    TextInput,
    Button,
    Link as CarbonLink,
    Theme,
    Header,
    HeaderName,
} from '@carbon/react';
import { Login, Password, ArrowRight } from '@carbon/icons-react';
import axios from 'axios';
import logo from '../assets/logoicon.png';

const LoginPage = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(baseURL + '/api/login', { username, password });
            const { token, username: loggedInUsername, isPro, isAdmin } = response.data;
            onLogin(loggedInUsername, isPro, isAdmin, token);

            if (isAdmin) {
                navigate('/admin');
            } else {
                navigate('/app');
            }
        } catch (error) {
            console.error('Login error:', error);
            setError('Invalid username or password');
        }
    };

    return (
        <Theme theme="white">
            <div className="login-page">
                <Header className="login-header">
                    <HeaderName prefix="" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                        <img src={logo} alt="Cashflowee" />
                        Cashflowee
                    </HeaderName>
                </Header>

                <div className="login-container">
                    <div className="login-content">
                        <Stack gap={7}>
                            <div className="login-header-content">
                                <h1>Welcome back</h1>
                                <p>Log in to manage your finances with Cashflowee</p>
                            </div>

                            <form onSubmit={handleLogin}>
                                <Stack gap={5}>
                                    <TextInput
                                        id="username"
                                        labelText="Username"
                                        placeholder="Enter your username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        renderIcon={Login}
                                        required
                                    />

                                    <TextInput.PasswordInput
                                        id="password"
                                        labelText="Password"
                                        placeholder="Enter your password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        renderIcon={Password}
                                        required
                                    />

                                    {error && (
                                        <div className="error-message">
                                            {error}
                                        </div>
                                    )}

                                    <div className="forgot-password">
                                        <CarbonLink href="#">Forgot password?</CarbonLink>
                                    </div>

                                    <Button
                                        type="submit"
                                        renderIcon={ArrowRight}
                                        size="lg"
                                        className="login-button"
                                    >
                                        Log in
                                    </Button>
                                </Stack>
                            </form>

                            <div className="signup-prompt">
                                <p>
                                    Don't have an account?{' '}
                                    <CarbonLink as={Link} to="/signup">Sign up</CarbonLink>
                                </p>
                            </div>
                        </Stack>
                    </div>
                </div>
            </div>
        </Theme>
    );
};

export default LoginPage;