import React from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Box, Typography, Container, createTheme, ThemeProvider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const theme = createTheme({
    palette: {
        primary: {
            main: '#72cc50',
            light: '#bfd835',
            dark: '#029975',
        },
        secondary: {
            main: '#bfd835',
            light: '#d3e85a',
            dark: '#9eb82a',
        },
        background: {
            default: '#fff',
            paper: '#FFFFFF',
        },
        text: {
            primary: '#333333',
            secondary: '#666666',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
            fontWeight: 600,
            fontSize: '2rem',
        },
        body1: {
            fontSize: '1rem',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    textTransform: 'none',
                    fontWeight: 400,
                    padding: '10px 20px',
                },
            },
        },
    },
});

const PayPalPaymentPage = () => {
    const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const navigate = useNavigate();
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const handlePayment = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: '2.99',
                    },
                },
            ],
        });
    };

    const handleApprove = async (data, actions) => {
        try {
            // Capture the funds from the transaction
            const details = await actions.order.capture();
            console.log('Transaction completed by', details.payer.name.given_name);

            // Call our API to confirm the payment and upgrade the user's plan
            const response = await axios.post(
                `${baseURL}/api/payment/success`,
                {
                    paymentId: details.id,
                    payerId: details.payer.payer_id,
                },
                {
                    headers: { Authorization: localStorage.getItem('token') }
                }
            );

            console.log('Server response:', response.data);

            // Update local storage to reflect the new pro status
            localStorage.setItem('isPro', 'true');

            // Redirect to a success page or the main app
            navigate('/payment-success');
        } catch (error) {
            console.error('Error processing payment:', error);
            // Handle the error (e.g., show an error message to the user)
            alert('There was an error processing your payment. Please try again.');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="sm">
                <Box sx={{ mt: 8, textAlign: 'center' }}>
                    <Typography variant="h4" gutterBottom>
                        Complete Your Pro Plan Purchase
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4 }}>
                        You're just one step away from unlocking all Pro features!
                    </Typography>
                    <PayPalScriptProvider options={{ 'client-id': paypalClientId }}>
                        <PayPalButtons
                            createOrder={handlePayment}
                            onApprove={handleApprove}
                            style={{
                                color: 'blue',
                                shape: 'rect',
                                label: 'pay',
                                height: 40,
                            }}
                        />
                    </PayPalScriptProvider>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default PayPalPaymentPage;