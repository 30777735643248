import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import CashflowApp from './components/cashflowapp/Main';
import AdminDashboard from './components/admin/AdminDashboard';
import PayPalPaymentPage from './components/PayPalPaymentPage';
import PaymentSuccessPage from './components/PaymentSuccessPage';
import ProfilePage from './components/cashflowapp/ProfilePage';
import './carbon-styles.scss';
import { Theme } from '@carbon/react';

const App = () => {
  const [user, setUser] = useState(() => localStorage.getItem('user') || null);
  const [isPro, setIsPro] = useState(() => localStorage.getItem('isPro') === 'true');
  const [isAdmin, setIsAdmin] = useState(() => localStorage.getItem('isAdmin') === 'true');

  useEffect(() => {
    console.log('Current state:', { user, isPro, isAdmin });
  }, [user, isPro, isAdmin]);

  const handleLogin = (username, userIsPro, userIsAdmin, token) => {
    console.log('handleLogin called with:', { username, userIsPro, userIsAdmin, token });
    setUser(username);
    setIsPro(userIsPro);
    setIsAdmin(userIsAdmin);
    localStorage.setItem('token', token);
    localStorage.setItem('user', username);
    localStorage.setItem('isPro', userIsPro);
    localStorage.setItem('isAdmin', userIsAdmin);
  };

  const handleLogout = () => {
    console.log('handleLogout called');
    setUser(null);
    setIsPro(false);
    setIsAdmin(false);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('isPro');
    localStorage.removeItem('isAdmin');
  };

  const updateUserStatus = (newIsPro) => {
    console.log('Updating user status. New isPro:', newIsPro);
    setIsPro(newIsPro);
    localStorage.setItem('isPro', newIsPro);
  };

  return (
    <Theme theme="g100">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/paypal-payment" element={<PayPalPaymentPage updateUserStatus={updateUserStatus} />} />
          <Route path="/payment-success" element={<PaymentSuccessPage updateUserStatus={updateUserStatus} />} />
          <Route
            path="/app"
            element={
              user && !isAdmin ? (
                <CashflowApp user={user} onLogout={handleLogout} isPro={isPro} updateUserStatus={updateUserStatus} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/profile"
            element={
              user ? (
                <ProfilePage user={user} isPro={isPro} updateUserStatus={updateUserStatus} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/admin"
            element={
              user && isAdmin ? (
                <AdminDashboard onLogout={handleLogout} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </Router>
    </Theme>
  );
};

export default App;