import React from 'react';
import { AppBar, Toolbar, Box, Chip, Button, IconButton } from '@mui/material';
import { ExitToApp, AccountCircle } from '@mui/icons-material';
import { Link } from 'react-router-dom';

// Import logo
import logo from '../../assets/cashfloweewhitelogo.png';

const CashflowAppBar = ({ isPro, onLogout }) => (
    <AppBar position="static" elevation={0} sx={{ backgroundColor: 'background.gray', padding: 1 }}>
        <Toolbar>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                <img src={logo} alt="Cashflowee Logo" style={{ marginRight: 10, marginTop: 0, width: 250 }} />
            </Box>
            <Chip
                label={isPro ? "PRO PLAN" : "FREE PLAN"}
                color={isPro ? "secondary" : "default"}
                sx={{ mr: 2, width: 100, color: '#FFFFFF', bgcolor: isPro ? 'primary.dark' : 'rgba(255,255,255,0.2)' }}
            />
            <IconButton
                component={Link}
                to="/profile"
                color="inherit"
                sx={{ mr: 2, color: '#FFFFFF', '&:hover': { backgroundColor: 'rgba(255,255,255,0.1)' } }}
            >
                <AccountCircle />
            </IconButton>
            <Button
                color="inherit"
                onClick={onLogout}
                startIcon={<ExitToApp />}
                sx={{ color: '#FFFFFF', '&:hover': { backgroundColor: 'rgba(255,255,255,0.1)' } }}
            >
                Logout
            </Button>
        </Toolbar>
    </AppBar>
);

export default CashflowAppBar;