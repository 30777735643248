import React from 'react';
import { Snackbar, Button, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const NotificationToast = ({ open, message, onClose, action }) => {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={open}
            onClose={onClose}
            message={message}
            action={
                <>
                    <Button color="secondary" size="small" component={Link} to="/paypal-payment">
                        {action}
                    </Button>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </>
            }
        />
    );
};

export default NotificationToast;